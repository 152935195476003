// 해상도 분기점
$breakpoint-min-size: 1280px; // 최소
$breakpoint-max-size: 1600px; // 최대

// colors
$white: white;
$gray: #fafafa;
$black_45: #00000073;
$black_88: #000000e0;
$danger: #ff4d4f;
$uploadTagColor: #1677ff;

// 반응형 breakpoint 추가
$mobile-width: 768px;

// 믹스인 추가
@mixin mobile {
  @media screen and (max-width: #{$mobile-width}) {
    @content;
  }
}
